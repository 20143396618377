import {getQaList} from '@/untils/api.js'
const problem ={
  state:{
    problemList:[],
    open:[],
    indexProblemList:[]
  },
  getters:{
    problemList:state=>{
      return state.problemList
    },
    open:state=>{
      return state.open
    },
    indexProblemList: state => {
      return state.indexProblemList
    }
  },
  actions:{
    getProblemList(content,params){
      getQaList(params).then(res=>{       
        content.state.indexProblemList=[]
        if(res.flag){
          if(res.data.length>0){
            content.state.problemList=res.data
            res.data[0].problem.forEach((item,index)=>{
              if(index<6){
                content.state.indexProblemList.push(item)
              }         
            })
          }
        }
       
      })
    },
    setOpen(content,arr){
      content.state.open=arr
    }
  }
}
export default problem