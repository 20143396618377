import axios from 'axios'
import router from '../router'
import { message } from 'ant-design-vue'
import store from '../store'
var service = axios.create({
	timeout: 15000,
	baseURL: process.env.VUE_APP_API_BASE_URL,
})
const MyAxios = {
	get(url, params) {
		return new Promise((resolve, reject) => {
			service({
				method: 'get',
				url,
				params: params,
			})
				.then((res) => {
					//axios返回的是一个promise对象
					resolve(res.data) //resolve在promise执行器内部
				})
				.catch((err) => {
					console.log(err, '异常')
				})
		})
	},
	post(url, params) {
		return new Promise((resolve, reject) => {
			service({
				method: 'post',
				url,
				data: params,
			})
				.then((res) => {
					resolve(res.data)
				})
				.catch((err) => {
					console.log(err, '异常')
				})
		})
	},
	uploadImg(datas) {
		//上传
		// eslint-disable-next-line quotes
		var fileData = new FormData()
		fileData.append('file', datas.file)
		return this.post(`/upload/upload`, fileData, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		})
	},
	down(url,params) {
		return new Promise((resolve, reject) => {
			service({
				method: 'get',
				url,
        params: params,
				responseType: 'blob', //二进制流
			})
				.then((res) => {
					//axios返回的是一个promise对象
					resolve(res) //resolve在promise执行器内部
				})
				.catch((err) => {
					console.log(err, '异常')
				})
		})
	},
}
//请求拦截
service.interceptors.request.use(
	(config) => {
    let token = localStorage.getItem('Authorization')
		if (token) {
			// 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization=token
		}
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)
//响应拦截
service.interceptors.response.use(
	(response) => {
		//拦截响应，做统一处理
		if (response.data.code == -1 && !!response.data.data) {
			if (
				response.data.data.errCode == 401 ||
				response.data.data.errCode == 409
			) {
				// router.push({path:'/Login',query:{url:router.currentRoute.fullPath}})

				return
			}
		}
		return response
	},
  // 接口错误状态处理，也就是说无响应时的处理
  error => {
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)
export default MyAxios
