import {getHirePlan ,getJobs,getComingHirePlan,getTerminateHirePlan} from '@/untils/api.js'
const plan ={
  state:{
    planList:[],
    planListComing:[],
    planListTerminate:[],
    jobList:[],
  },
  getters:{
    planList:state=>{
      return state.planList
    },
    planListComing:state=>{
      return state.planListComing
    },
    planListTerminate:state=>{
      return state.planListTerminate
    },
    jobList:state=>{
      return state.jobList
    }
  },
  actions:{
    getHireplan(content,param){
      return new Promise ((resolve,reject)=>{
        getHirePlan(param).then(res=>{
          if(res.flag){
            const data=content.state.planList
            if(param.parmar.page==1){
              content.state.planList=res.data.hirePlanInfoList
            }else {
              content.state.planList= data.concat(res.data.hirePlanInfoList);   
            }
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getComingHirePlan(content,param){
      return new Promise ((resolve,reject)=>{
        getComingHirePlan(param).then(res=>{
          if(res.flag){
            const data=content.state.planListComing
            if(param.parmar.page==1){
              content.state.planListComing=res.data.hirePlanInfoList
            }else {
              content.state.planListComing= data.concat(res.data.hirePlanInfoList);   
            }
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getTerminateHirePlan(content,param){
      return new Promise ((resolve,reject)=>{
        getTerminateHirePlan(param).then(res=>{
          if(res.flag){
            const data=content.state.planListTerminate

            if(param.parmar.page==1){
              content.state.planListTerminate=res.data.hirePlanInfoList
            }else {
              content.state.planListTerminate= data.concat(res.data.hirePlanInfoList);   
            }
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getJobs(content,param){
      return new Promise ((resolve,reject)=>{
        getJobs(param).then(res=>{
          if(res.flag){          
            content.state.jobList=res.data
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    } 
  },
}
export default plan