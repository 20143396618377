import { 
  login,
  register ,
  resetPassword, 
  getAgreement,
  getPrivacy,
  getSmsCaptcha,
  checkVercode
} from '@/untils/api.js'
const user = {
	state: {
    lastVisitRoute:{}
  },
  getters:{
    lastVisitRoute:state=>{
      return state.lastVisitRoute
    }
  },
	actions: {
    setlastVisitRoute(content,params){
      console.log(params)
      content.state.lastVisitRoute=params
    },
		login({ commit },params) {
			login(params.data).then((res) => {
        if(res.flag){
          localStorage.setItem('Authorization', res.extra.jwtToken.token); // 登录成功后将token存储在localStorage中
        }
        params.callback(res)
			})
		},
		register(content,params) {
      return new Promise((resolve,reject)=>{
        register(params).then((res) => {
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })	
		},
    getAgreement(){
      return new Promise((resolve, reject)=>{
        getAgreement().then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getPrivacy(){
      return new Promise((resolve, reject)=>{
        getPrivacy().then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getSmsCaptcha(content,param){
      return new Promise((resolve, reject)=>{
        getSmsCaptcha(param).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    checkVerCode(content,param){
      return new Promise((resolve, reject)=>{
        checkVercode(param).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    resetpwd(content,param){
      return new Promise((resolve, reject)=>{
        resetPassword(param).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    }
	},
}
export default user
