import axios from './aixos.js'

export function login(params) {
	return axios.post('/login/submit', params)
}

export function register (params){
  return axios.post('/register/submit',params)
}
// 校验验证码
export function checkVercode(params){
  return axios.post('/login/checkCode',params)
}
// 重置密码
export function resetPassword(params){
  return axios.post('/login/reset/password',params)
}
// 获取用户协议
export function getAgreement (){
  return axios.get('/textbook/agreement')
}
export function getPrivacy (){
  return axios.get('/textbook/privacy')
}
// 获取正在报名招聘计划
export function getHirePlan (params){
  return axios.post(`/hirePlan/common/apply/query${params.code}`,params.parmar)
}
// 获取即将开始招聘计划
export function getComingHirePlan (params){
  return axios.post(`/hirePlan/common/coming/query${params.code}`,params.parmar)
}// 获取已经结束招聘计划
export function getTerminateHirePlan (params){
  return axios.post(`/hirePlan/common/terminate/query${params.code}`,params.parmar)
}
//获取招聘计划详情
export function getJobs(params){
  return axios.get('/hirePlan/getJobs',params)
}
// 获取用户信息
export function getUserInfo(params){
  return axios.post('/user/info',params)
}
// 新增个人资料
export function insertUserProfile(params){
  return axios.post('/userprofile/insertUserProfile',params)
}
// 获取个人资料
export function getUserProfile(params){
  return axios.get('/userprofile/getUserProfile',params)
}
// 获取上传文件
export function getUpload(id){
  return axios.get(`/upload/get/file/`+id)
}
// 上传证件照
export function upload(params){
  return axios.post(`/upload/upload`,params)
}
// 修改个人资料
export function updataUserProfile(params){
  return axios.post('/userprofile/updateUserProfile',params)
}
// 认证
export function idVerify(){
  return axios.post('userprofile/idCard/verify')
}
// 获取常见问题列表
export function getQaList(params){
  return axios.post(`/qa/list${params.code}`,params.parma)
}
//获取通知消息
export function getLogList(params){
  return axios.post('/log/list ',params)
}
// 提交报名信息
export function insertEntryApply(params){
  return axios.post('/apply/position',params)
}
// 获取岗位下拉菜单
export function getJobSelect(params){
  return axios.post('/job/queryLike',params)
}
//获取我的报考列表
export function applyList(params){
  return axios.post('/common/apply/list/query',params)
}
// 获取我的考试列表
export function examList(params){
  return axios.post('/apply/exam/query',params)
}
// 下载准考证
export function downCard(params){
  return axios.down('/apply/exam/card/download',params)
}
// 下载附件
export function downFile(params){
  return axios.down('/upload/downloadFile3',params)
}
// 获取报名字段
export function getEnterApply(params){
  return axios.post('/entry/field/query',params)
}
// 获取报名岗位 
export function getJob(params){
  return axios.post('/job/fuzzy/query',params)
}
// 获取提交报名信息参数字段
export function getApplyQuery(){
  return axios.post('/common/apply/table/query')
}
// 提交报名信息
export function applySave(params){
  return axios.post('/common/apply/table/save',params)
}
export function getSmsCaptcha (params ) {  // 验证码
  return axios.post(`/sms/send/checkCode`,params)
}
// 支付
// 提交订单
export function submitOrder(params) {
  return axios.post('/pay/submit/order',params)
}
// 提交支付
export function pay(params){
  return axios.post('/pay/submit/pay',params)
}
// 订单详情
export function orderDetail(params){
  return axios.post('/pay/order/info',params)
}
// 获取招考计划的支付信息
export function gethirePlanPay(params){
  return axios.get('/hirePlan/get',params)
}
// 订单列表
export function orderList(params){
  return axios.post('/pay/order/list',params)
}
// 消息已阅
export function logRead(params){
  return axios.post('/log/read',params)
}
// 获取报名信息
export function getsingUpInfo(params){
  return axios.post(`/apply/position/query/?id=${params}`)
}
// 重新提交报名信息
export function resetsingUpInfo(params){
  return axios.post('/apply/position/retry',params)
}
// 获取logo与bannerUrl
export function getLogo(params){
  console.log(params)
  return axios.post(`/website/logo/fetch/?code=${params}`)
}
export function entrancePhoto(params){
  return axios.post(`/upload/entrance/photo`,params)
}