import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/login'
import planList from './modules/plan'
import createPersistedState from 'vuex-persistedstate'
import userProfile from './modules/user'
import problem from './modules/qa'
import notice from './modules/notice'
import signUp from './modules/signUp'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		namespaced: true,
		user,
    planList,
    userProfile,
    problem,
    notice,
    signUp
	},
	state: {
		
	},
	getters: {},
	mutations: {
	},
	actions: {},

	plugins: [createPersistedState()],
})
