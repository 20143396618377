<template>
  <div>
    <div style="height:60px">
      <div class="header" >
        <div class="header-main">
          <div class="logo">
            <img v-if="logoUrl!=''" :src="logoUrl"
                alt="">
          </div>
          <div class="header-nav">
            <a-menu mode="horizontal"
                    v-model="current">
              <a-menu-item key="1"
                          @click="go('/index')"> 首页 </a-menu-item>
              <a-menu-item key="2"
                          @click="go('/recruitList')"> {{(code=='78e8dffe65a2898eef68a33b8db35b78'||code=='34adeb8e3242824038aa65460a47c29e'||code=='358f9e7be09177c17d0d17ff73584307')?'报名项目':'招聘计划'}} </a-menu-item>
              <a-menu-item key="3"
                          @click="go('/center')"> 报名中心 </a-menu-item>
              <a-menu-item key="4"
                          @click="go('/problem')"> 常见问题 </a-menu-item>
            </a-menu>
          </div>
          <div class="login" v-if="login">
            <a-button size="small"
                      style="border-radius: 2px;"
                      @click="goLogin()">登录</a-button>
            <a-button type="primary"
                      @click="goRegister()"
                      style="background:#3058EE;border-radius: 2px;"
                      size="small">注册</a-button>
          </div>
          <div class="outLogin" v-if="!login">
            <a-button size="small"
              style="border-radius: 2px;"
              @click="outLogin()">退出登录</a-button>
          </div>
        </div>
      </div>
    </div>
      
    
    <div style="width:100%;background:#fafafc;min-width: 1190px;">
      <!--内容区域-->
      <router-view />
    </div>
    <div class="footer">
      <div class="company"> 
        <img src="@/assets/jinghui.png" alt="">
        © 京ICP备14042574号-1 
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  data() {
    return {
      current: [],
      login:true,
      code:''
    }
  },
  created(){
    if(this.$route.query.code){
      localStorage.setItem('code', this.$route.query.code);
    } 
  },
  mounted(){
    this.getuserinfo().then(res=>{
      if(res.flag){
        this.login=false
      }else {
        this.login=true
      }
    })
    this.getpath();
    this.code=localStorage.getItem('code')
    this.getLogoAndBanner(localStorage.getItem('code')).then(res=>{
      if(res.flag){
        document.title=localStorage.getItem('title')
      }
    })
  },
  watch:{
    '$route':'getpath'
  },
  computed:{
    ...mapGetters([
      'logoUrl',
      'lastVisitRoute'
    ])
  },
  methods: {
    ...mapActions([
      'getLogoAndBanner',
      "getuserinfo",
      'setlastVisitRoute'
    ]),
    getpath(){
      if(this.$route.path.indexOf('/index')!=-1){
        this.current[0]='1'
      }else if(this.$route.path.indexOf('/plan')!=-1||this.$route.path.indexOf('/signUp')!=-1||this.$route.path=='/recruitList'){
        this.current[0]='2'
      }else if(this.$route.path.indexOf('/center')!=-1){
        this.current[0]='3'
      }else {
        this.current[0]='4'
      }
    },
    go(path) {
      if(path=='/center'){
        this.getuserinfo().then(res=>{
          if(res.flag){
            this.$router.push(path)
          }else {
            let _this=this
            this.getpath()
            this.$confirm({
              content: '请先去登录',
              okText:'去登录',
              okButtonProps:{
                  style:{background:'#3058ee',border:'none',borderRadius:'2px'}
              },
              cancelText:'取消',
              cancelButtonProps:{
                  style:{border: '1px solid #DADADC',borderRadius:'2px',color:" #5C5E66"}
              },
              onOk() {
                _this.$router.replace('/login')
              },
              onCancel() {},
            });
          }
        })
      }else {
        this.$router.push(path)
      }  
    },
    goLogin() {    
      this.setlastVisitRoute({path:this.$route.path,query:this.$route.query})
      this.$router.push('/login')
    },
    goRegister() {
      this.$router.push('/register')
    },
    outLogin(){
      localStorage.removeItem('Authorization')
      // window.localStorage.clear()
      console.log(this.$route.query)
      this.setlastVisitRoute({path:this.$route.path,query:this.$route.query})
      this.$router.push('/login')
    }
  },
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  min-width: 1190px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 12px;
  color: #5C5E66;
  background: #FAFAFC;
  img {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 8px;
  }
}
@media screen and (max-width: 480px) {
  .header{
    position: static !important;
  }
}
.header {
  display: flex;
  width: 100%;
  min-width: 1190px;
  height: 60px;
  line-height: 60px;
  position: fixed;
  z-index: 1000;
  background: #ffffff;
  .header-main {
    width: 62%;
    min-width: 1190px;
    margin: 0 auto;
    display: flex;
    .logo {
      vertical-align: middle;
      margin-right: 82px;
      img {
        width: 66px;
        height: 30px;
      }
    }
    .header-nav {
      .ant-menu-item {
        height: 60px;
        padding-right: 0px;
        padding-left: 0px;
        margin-right: 20px;
      }
      .ant-menu-item:hover {
        color: #3058EE;
        border-bottom: 2px solid #3058EE ;
      }
      .ant-menu-horizontal {
        line-height: 60px;
        border: none;
        .ant-menu-item-selected {
          color:#3058ee;
          border-bottom: 2px solid #3058EE ;
        }
      }
    }
    .outLogin{
      margin-left: auto;
      button {
        width: 88px;
        height: 32px;
        margin-right: 8px;
        font-size: 14px;
      }
    }
    .login {
      margin-left: auto;
      display: flex;
      align-items: center;
      button {
        width: 88px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }
}
.ant-btn-primary {
  border: none;
}

</style>
