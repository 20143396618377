import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from './../views/Layout/Layout'
import Center from './../views/registCenter/registCenter'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/index',
		component: Layout,
		children: [
			{
				path: 'index',
				name: 'index',
				component: () => import('@/views/index/index.vue'),
			},
			{
				path: 'plan',
				name: 'plan',
				component: () => import('@/views/plan/plan.vue'),
			},
			{
				path: 'signUp',
				name: 'signUp',
				component: () => import('@/views/plan/signUp/signUp.vue'),
			},
      {
				path: 'recruitList',
				name: 'recruitList',
				component: () => import('@/views/plan/recruitList.vue'),
			},
			{
				path: 'center',
				name: 'center',
				component: Center,
				redirect: '/center/personal-data',
				children: [
					{
						path: 'personal-data',
						name: 'personal-data',
						component: () =>
							import('@/views/registCenter/component/personalData'),
					},
					{
						path: 'sign-list',
						name: 'sign-list',
						component: () => import('@/views/registCenter/component/signList'),
					},
					{
						path: 'order-list',
						name: 'order-list',
						component: () => import('@/views/registCenter/component/orderList'),
					},
					{
						path: 'exam-list',
						name: 'exam-list',
						component: () => import('@/views/registCenter/component/examList'),
					},
					{
						path: 'notice',
						name: 'notice',
						component: () => import('@/views/registCenter/component/notice'),
					},
				],
			},
			{
				path: 'problem',
				name: 'problem',
				component: () => import('@/views/problem/problem.vue'),
			},
      
		],
    
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login.vue'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/login/register.vue'),
	},
  {
		path: '/setPassword',
		name: 'setPassword',
		component: () => import('@/views/login/setPassword.vue'),
	},
  {
		path: '/resetPassword',
		name: 'resetPassword',
		component: () => import('@/views/login/resetPassword.vue'),
	},
  {
    path: '/noPermission',
    name: 'noPermission',
    component: () => import('@/views/noPermission.vue'),
  },
	// {
	// 	path: '/about',
	// 	name: 'about',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () =>
	// 		import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
	// },
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})
router.beforeEach((to,from,next)=>{
  document.title=localStorage.getItem('title')?localStorage.getItem('title'):'--'
  if(to.path.indexOf('/center')>-1||to.path.indexOf('/signUp')>-1){
    if(localStorage.getItem('Authorization')){
      next()
    }else {
      alert('请先登录');
      next('/login')
    }
  }else {
    next()
  }
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export default router
