import {
  examList,
  getUpload,
  insertUserProfile,
  getUserProfile,
  getsingUpInfo ,
  getUserInfo,
  applyList,
  orderList,
  idVerify,
  updataUserProfile,
  getLogo,
  resetsingUpInfo,
  upload,
  downCard,
  downFile,
  getJob
} from '@/untils/api.js'
const userProfile={
  state:{
    userdata:{},
    applyList:[],
    orderList:[],
    userId:'',
    examList:[],
    logoUrl:'',
    bannerUrls:[],
    title:'',
    signInfo:{},
    addSignItem:[],
    jobNameList:[],
    detailInfo:{}
  },
  getters:{
    addSignItem:state=>{
      return state.addSignItem
    },
    jobNameList:state=>{
      return state.jobNameList
    },
    logoUrl:state=>{
      return state.logoUrl
    },
    signInfo:state=>{
      return state.signInfo
    },
    detailInfo:state=>{
      return state.detailInfo
    },
    title:state=>{
      return state.title
    },
    bannerUrls:state=>{
      return state.bannerUrls
    },
    userdata:state=>{
      return state.userdata
    },
    applyList:state=>{
      return state.applyList
    },
    orderList:state=>{
      return state.orderList
    },
    userId:state=>{
      return state.userId
    },
    examList:state=>{
      return state.examList
    }
  },
  actions:{
    getPerjobNameList(content,params){
      return new Promise ((resolve,reject)=>{
        getJob(params).then(res=>{
          if(res.flag){
            content.state.jobNameList=res.data
          }
          resolve(res)
        })
      }) 
    },
    getUserProfile(content,params){
      return new Promise ((resolve,reject)=>{
        getUserProfile(params).then(res=>{
          content.state.userdata=res.data
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
        
    },
    getuserinfo(content){
      return new Promise ((resolve,reject)=>{
        getUserInfo().then(res=>{
          content.state.userId=res.extra.userId
          resolve(res)
        }).catch(error=>{
          reject(error)
          localStorage.removeItem('Authorization')
        })
      })
    },
    getapplyList(content,params){
      return new Promise ((resolve,reject)=>{
        applyList(params).then(res =>{
          if(res.flag){
            content.state.applyList=res.data.resultList
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
      
    },
    uploadPhto(content,params){
      return new Promise((resolve,reject)=>{
        upload(params).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
      
    },
    getOrderList(content,params){
      return new Promise ((resolve,reject)=>{
        orderList(params).then(res =>{
          if(res.flag){
            content.state.orderList=res.data.resultList
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getSingUpInfo(content,parmar){
      return new Promise ((resolve,reject)=>{
        getsingUpInfo(parmar).then(res=>{
          if(res.flag){
            let data =res.data
            content.state.signInfo=data
            for (let key in data){
              if(key != 'otherInfo'){
                if(!data[key]){
                  delete data[key]
                }
                if(data[key]&&data[key].add){                  
                  data[key]['showTitle'] = data[key].fieldLists[0].find(item => !item.hide) ? true : false;
                  data[key].fieldLists.forEach(item=>{
                    item.forEach(e=>{
                      if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                        if(e.value[0]&&!e.value[1]){
                          e.today=true
                        }else {
                          e.today=false
                        }
                      }
                    })
                    return item.filter(e => !e.hide&&e.status)
                  })
                  let addItem= [...JSON.parse(JSON.stringify(data[key].fieldLists[0]))]
                  addItem.forEach(item=>{
                    if(item.type.name=='dateRangeYM'||item.type.name=='dateRangeYMD'){
                      item.value=[null,null]
                      item.today=false
                    }else if(item.type.name=='多选'){
                      item.value=[]
                    }else if(item.type.name=='附件'){
                      item.value='';
                      item.extra=null;
                      item.fileList=[]
                    }else {
                      item.value=''
                    }
                  })
                  content.state.addSignItem.push({type:data[key].tableName,list:addItem})
                }else {
                  if(data[key]&&key!='hirJobInfo'){  
                    data[key]['showTitle'] = data[key].fieldList.find(item => !item.hide) ? true : false;
                    data[key].fieldList.forEach(item=>{
                      if(item.type.name=='dateRangeYM'||item.type.name=='dateRangeYMD'){
                        if(item.value[0]&&!item.value[1]){
                          item.today=true
                        }else {
                          item.today=false
                        }
                      }
                    })
                    data[key].fieldList=data[key].fieldList.filter(item => !item.hide&&item.status)
                  }
                }
              }else {
                if(data[key]&&data[key].length>0){
                  data[key].forEach(e=>{
                    if(e.add){
                      if(e.fieldLists.length>0){
                        e['showTitle'] = e.fieldLists[0].find(f => !f.hide) ? true : false;
                        e.fieldLists.forEach(item=>{
                          item.forEach(option=>{
                            if(option.type.name=='dateRangeYM'||option.type.name=='dateRangeYMD'){
                              if(option.value[0]&&!option.value[1]){
                                option.today=true
                              }else {
                                option.today=false
                              }
                            }
                          })
                          return item.filter(e => !e.hide&&e.status)
                        })
                        let addItem= [...JSON.parse(JSON.stringify(e.fieldLists[0]))]
                      addItem.forEach(item=>{
                        if(item.type.name=='dateRangeYM'||item.type.name=='dateRangeYMD'){
                          item.value=[null,null]
                          item.today=false
                        }else if(item.type.name=='多选'){
                          item.value=[]
                        }else if(item.type.name=='附件'){
                          item.value='';
                          item.extra=null;
                          item.fileList=[]
                        }else {
                          item.value=''
                        }
                        
                      })
                      content.state.addSignItem.push({type:e.tableName,list:addItem})
                      }
                      
                      
                    }else {
                      console.log(e)
                      if(e){
                        e['showTitle'] = e.fieldList.find(f => !f.hide) ? true : false;
                        e.fieldList.forEach(item=>{
                          if(item.type.name=='dateRangeYM'||item.type.name=='dateRangeYMD'){
                            if(item.value[0]&&!item.value[1]){
                              item.today=true
                            }else {
                              item.today=false
                            }
                          }
                        })
                        e.fieldList=e.fieldList.filter(e => !e.hide&&e.status)
                      }
                      
                    }
                  })
                }
              }
            }
            content.state.detailInfo={...JSON.parse(JSON.stringify(data))}
            content.state.detailInfo['basicInfo'].fieldList=content.state.detailInfo['basicInfo'].fieldList.filter((e)=>{
              if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                if(e.value[0]){
                  return true
                }
              }else if(e.type.name=='多选'){
                if(e.value.length>0){
                  return true
                }
              }else {
                if(e.value!=""){
                  
                  return true
                }
              }
            })
            if(content.state.detailInfo['educationInfo']){
              content.state.detailInfo['educationInfo'].fieldLists=content.state.detailInfo['educationInfo'].fieldLists.map((option)=>{
                let arr = option.filter(e=>{
                  if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                    if(e.value[0]){
                      return true
                    }
                  }else if(e.type.name=='多选'){
                    if(e.value.length>0){
                      return true
                    }
                  }else {
                    if(e.value&&e.value!=""){
                      return true
                    }
                  }
                })            
                option = [...arr]
                return option;
              })
              for(let i=0 ;i<content.state.detailInfo['educationInfo'].fieldLists.length;i++){
                if(content.state.detailInfo['educationInfo'].fieldLists[i].length==0){
                  content.state.detailInfo['educationInfo'].fieldLists.splice(i,1)
                  i=i-1
                }
              }
              if(content.state.detailInfo['educationInfo'].fieldLists[0]){
                content.state.detailInfo['educationInfo']['showTitle'] = content.state.detailInfo['educationInfo'].fieldLists[0].find(item => !item.hide) ? true : false;
              }else {
                content.state.detailInfo['educationInfo']['showTitle'] = false
              }  
              
            }
            
            if(content.state.detailInfo['workInfo']){
              content.state.detailInfo['workInfo'].fieldLists=content.state.detailInfo['workInfo'].fieldLists.map((option)=>{
                let arr = option.filter(e=>{
                  if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                    if(e.value[0]){
                      return true
                    }
                  }else if(e.type.name=='多选'){
                    if(e.value.length>0){
                      return true
                    }
                  }else {
                    if(e.value!=""){
                      return true
                    }
                  }
                })            
                option = [...arr]
                return option;
              })
              for(let i=0 ;i<content.state.detailInfo['workInfo'].fieldLists.length;i++){
                if(content.state.detailInfo['workInfo'].fieldLists[i].length==0){
                  content.state.detailInfo['workInfo'].fieldLists.splice(i,1)
                  i=i-1
                }
              }
              if(content.state.detailInfo['workInfo'].fieldLists[0]){
                content.state.detailInfo['workInfo']['showTitle'] = content.state.detailInfo['workInfo'].fieldLists[0].find(item => !item.hide) ? true : false;
              }else {
                content.state.detailInfo['workInfo']['showTitle'] = false
              } 
            }
            
            if(content.state.detailInfo['otherInfo']){
              content.state.detailInfo['otherInfo'].map(item=>{
                if(item.add){
                  item.fieldLists = item.fieldLists.map(option=>{
                    let arr = option.filter(e=>{
                      if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                        if(e.value[0]){
                          return true
                        }
                      }else if(e.type.name=='多选'){
                        if(e.value.length>0){
                          return true
                        }
                      }else {
                        if(e.value!=""){
                          return true
                        }
                      }
                    })            
                    option = [...arr]
                    return option;
                  })
                  for(let i=0 ;i<item.fieldLists.length;i++){
                    if(item.fieldLists[i].length==0){
                      item.fieldLists.splice(i,1)
                      i=i-1
                    }
                  }
                  if(item.fieldLists[0]){
                    item.showTitle=item.fieldLists[0].find(item => !item.hide) ? true : false;
                  }else {
                    item['showTitle'] = false
                  } 
                  
                }else {
                  item.fieldList=item.fieldList.filter((e)=>{
                    if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                      if(e.value[0]){
                        return true
                      }
                    }else if(e.type.name=='多选'){
                      if(e.value.length>0){
                        return true
                      }
                    }else {
                      if(e.value!=""){
                        return true
                      }
                    }
                  })
                  if(item.fieldList){
                    item.showTitle=item.fieldList.find(item => !item.hide) ? true : false;
                  }
                  
                }
                return item;
              })
            }
            console.log(content.state.detailInfo)
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    downLoadCard(content,parmar){
      return new Promise((resolve,reject)=>{
        downCard(parmar).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    downLoadFile(content,parmar){
      return new Promise((resolve,reject)=>{
        downFile(parmar).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    resetSingUpInfo(content,parmar){
      return new Promise ((resolve,reject)=>{
        resetsingUpInfo(parmar).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    toIdVerify(){
      return new Promise ((resolve,reject)=>{
        idVerify().then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
      
    },
    upUserProfile(content,params){
      return new Promise ((resolve,reject)=>{
        updataUserProfile(params).then(res =>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    insertUserProfile(content,params){
      return new Promise((resolve,reject)=>{
        insertUserProfile(params).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getUploadFile(content,id){
      return new Promise((resolve,reject)=>{
        getUpload(id).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getExamList(content,params){
      return new Promise((resolve,reject)=>{
        examList(params).then(res=>{
          if(res.flag){
            content.state.examList=res.data.resultList
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    getLogoAndBanner(content,params){
      return new Promise((resolve,reject)=>{
        getLogo(params).then(res=>{
          if(res.flag){
            console.log()
            content.state.logoUrl=res.data.logoUrl;
            content.state.bannerUrls=res.data.bannerUrls;
            content.state.title=res.data.title;
            localStorage.setItem('title',res.data.title)
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    }
  },
  

}
export default userProfile