<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  mounted(){
    this.getLogoAndBanner(localStorage.getItem('code'))
  },
  methods:{
    ...mapActions({
      getLogoAndBanner:'getLogoAndBanner'
    }),
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
