<template>
  <div style="background:#FAFAFC;padding-top:24px;padding-bottom:24px;">
    <div class="center-menu">
      <div class="side">
        <div class="head">
          <a-card>
            <div class="head-portrait" v-if="userdata.user_name">
              {{userdata.user_name| jobName}}
            </div>
            <div class="user-name">
              {{userdata.user_name}}
            </div>
            <div class="status">
              <span>{{userdata.identity_status|status}}</span>
            </div>
          </a-card>
        </div>
        <div class="menu">
          <a-menu style="width:210px"
                  :multiple="false"
                  :selectedKeys="key"
                  mode="vertical">
            <a-menu-item v-for="(item) in menuList"
                         :key="item.key">             
              <router-link :to="item.path"><span class="menu-blue" :class="{'opacity':item.key!=key[0]}"></span>{{item.title}}</router-link>
            </a-menu-item>
          </a-menu>
        </div>
      </div>
      <div class="center-content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
export default {
  data() {
    return {
      key:[],
      menuList: [
        {
          title: '个人资料',
          path: '/center/personal-data',
          key: '1',
        },
        {
          title: '我的报考',
          path: '/center/sign-list',
          key: '2',
        },
        {
          title: '我的订单',
          path: '/center/order-list',
          key: '3',
        },
        {
          title: '我的考试',
          path: '/center/exam-list',
          key: '4',
        },
        {
          title: '消息中心',
          path: '/center/notice',
          key: '5',
        },
      ],
    }
  },
  watch:{
    '$route':'getpath'
  },
  filters:{
    jobName(name){
      return name.split('')[0]
    },
    status(status){
      let str=''
      switch(status){
       
        case 0:
          str = '未认证';
          break;
        case 1:
           str = '已认证';
           break;
        case 2:
           str = '审核中';
           break;
        case -1:
           str = '认证失败';
           break;
        default: break;
      }
      return str
    }
  },
  computed:{
    ...mapGetters([
      'userdata',
      'userId'
    ])
  },
  mounted(){
    this.getuserinfo().then(res=>{
      this.getUserProfile({id:res.extra.userId})
    })
    this.getpath()
  },
  methods: {
    ...mapActions([
      'getUserProfile',
      'getuserinfo'
    ]),
    getpath(){
      if(this.$route.path.indexOf('/personal-data')!=-1){
        this.key=['1']
      }else if(this.$route.path.indexOf('/sign-list')!=-1){
        this.key=['2']
      }else if(this.$route.path.indexOf('/order-list')!=-1){
        this.key=['3']
      }else if(this.$route.path.indexOf('/exam-list')!=-1){
        this.key=['4']
      }else {
        this.key=['5']
      }
    },
  },
  
}
</script>
<style lang="less">
.center-menu {
  width: 62%;
  margin: 0 auto;
  min-width: 1190px;
  text-align: left;
  display: flex;
  .ant-card-bordered {
    border: none;
  }
  
  .side {
    .head {
      margin-bottom: 20px;
      text-align: center;
      .head-portrait {
        margin: 0 auto;
        width: 80px;
        height: 80px;
        background: linear-gradient(360deg, #3058ee 0%, #8da3f6 100%);
        border-radius: 50%;
        line-height: 80px;
        text-align: center;
        font-size: 28px;
        color: #ffffff;
      }
      .user-name {
        font-size: 18px;
        font-weight: 500;
        color: #040814;
        margin-top: 13px;
      }
      .status {
        font-size: 12px;
        color: #8e9095;
      }
      .authen {
        margin-top: 20px;
        button {
          width: 162px;
          height: 32px;
          background: #3058ee;
          border-radius: 2px;
          color: #ffffff;
        }
      }
    }
    .menu {
      .ant-menu-vertical {
        border: none;
        height:235px;
        .ant-menu-item {
          padding-right: 24px;
          padding-left: 0px;
          border-bottom: 1px solid #F2F2F5;
          margin-top: 0px;
          margin-bottom: 0px;
          height: 47px;
          line-height: 47px;
          .menu-blue {
            display: inline-block;
            width: 2px;
            height: 20px;
            background: #3058ee;
            vertical-align: middle;
            margin-right: 22px;
          }
          .opacity {
            opacity:0;
          }
        }
      }
      .ant-menu-item-selected > a {
        color:#3058ee
      }
      .ant-menu-item-active > a {
        color:#3058ee
      }
      .ant-menu-item-selected {
        background-color: #F7F9FF;
      }
    }
  }
  .center-content {
    height: 852px;
    width: 90%;
    min-width: 560px;
    margin-left: 20px;
  }
}
</style>