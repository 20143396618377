import {getLogList ,logRead} from '@/untils/api.js'
const notice ={
  state:{
    noticeList:[],
    lastPage:false
  },
  getters:{
    noticeList:state =>{
      return state.noticeList
    },
    lastPage:state =>{
      return state.lastPage
    }
  },
  actions:{
    LogList(content,params){
      return new Promise ((resolve,reject)=>{
        getLogList(params).then(res=>{
          if(res.flag){
            content.state.lastPage=res.data.lastPage;
            const data=content.state.noticeList
            if(params.page==1){
              content.state.noticeList=res.data.list
            }else {
              content.state.noticeList= data.concat(res.data.list);   
            }
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    logReaded(content,params){
      return new Promise ((resolve,reject)=>{
        logRead(params).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      }) 
    }
  }
}
export default notice