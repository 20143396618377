import {gethirePlanPay,orderDetail, insertEntryApply ,getJob, getEnterApply,getUserProfile,getUserInfo,submitOrder,pay,entrancePhoto} from '@/untils/api.js'
const signUp={
  state:{
    mySignForm: {},
    signForm:{},
    jobSelect:{},
    applyEntryList:{},
    hirePlanpay:{},
    addItem:[],
    templateId:'',
    visible:false,
    detailVisible:false,
    userApplyId:'',
    tips:'',
  },
  getters:{
    mySignForm:state=>{
      return state.mySignForm
    },
    signForm:state=>{
      return state.signForm
    },
    visible:state=>{
      return state.visible
    },
    detailVisible:state=>{
      return state.detailVisible
    },
    userApplyId:state=>{
      return state.userApplyId
    },
    jobSelect:state =>{
      return state.jobSelect
    },
    tips:state =>{
      return state.tips
    },
    applyEntryList:state=>{
      return state.applyEntryList
    },
    hirePlanpay:state =>{
      return state.hirePlanpay
    },
    addItem:state => {
      return state.addItem
    },
    templateId:state =>{
      return state.templateId
    }
  },
  actions:{
    insertEntryApply(content){
      let params= content.state.mySignForm
      insertEntryApply(params).then(res=>{
      })
    },
    getjobNameList(content,params){
      return new Promise ((resolve,reject)=>{
        getJob(params).then(res=>{
          resolve(res)
        })
      }) 
    },
    getEnterApply(content , params){
      return new Promise ((resolve,reject)=>{
        getEnterApply(params).then(res=> {
          if(res.flag){
            resolve(res)
            let data=res.data.templateInfo
            content.state.templateId=res.data.id
            if(res.data.tips){
              content.state.tips=res.data.tips
            }else {
              content.state.tips=''
            }
            
            for (let key in data){
              if(key != 'otherInfo'){
               if(data[key]&&data[key].add){ 
                data[key]['showTitle'] = data[key].fieldLists[0].find(item => !item.hide) ? true : false;
                data[key].fieldLists= data[key].fieldLists.map(item =>{
                  item.forEach(e=>{
                    if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                      e.startTime=null;
                      e.endTime=null;
                      e.endOpen=false;
                      e.today=false;
                    }                 
                  })
                  return item.filter(e => !e.hide&&e.status)
                })
                if(data[key].fieldLists[0].length==0){
                  delete data[key]
                }
               }else if(data[key]&&!data[key].add) {
                data[key]['showTitle'] = data[key].fieldList.find(item => !item.hide) ? true : false;
                data[key].fieldList.forEach(e=>{
                  if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                    e.startTime=null;
                    e.endTime=null;
                    e.endOpen=false;
                    e.today=false;
                  }
                  
                })
                data[key].fieldList=data[key].fieldList.filter(item => !item.hide&&item.status)
                if(data[key].fieldList.length==0){
                  delete data[key]
                }
               }
               
              }else {
                data[key].forEach(e =>{
                  if(e.add){
                    if(e.fieldLists.length>0){
                      e['showTitle'] = e.fieldLists[0].find(f => !f.hide) ? true : false;
                      e.fieldLists.map(item =>{
                        item.forEach(e=>{
                          if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                            e.startTime=null;
                            e.endTime=null;
                            e.endOpen=false;
                            e.today=false;
                          }
                        })
                        return item.filter(e => !e.hide&&e.status)
                      })
                    }
                  }else {
                    e['showTitle'] = e.fieldList.find(f => !f.hide) ? true : false;
                    e.fieldList.forEach(item=>{
                      if(item.type.name=='dateRangeYM'||item.type.name=='dateRangeYMD'){
                        item.startTime=null;
                        item.endTime=null;
                        item.endOpen=false;
                        item.today=false;
                      }else if(item.type.name=='附件'){
                        item.fileList=[]
                      }
                    })
                    e.fieldList=e.fieldList.filter(e => !e.hide&&e.status)
                  }
                })
                
              }
            }
            
            if( Object.keys(content.state.mySignForm).length===0){
              content.state.applyEntryList=data;
            }else {
              content.state.applyEntryList=content.state.mySignForm
            }
            
            getUserInfo().then(res=>{
              if(res.flag){
                getUserProfile({id:res.extra.userId}).then(res=>{
                  content.state.applyEntryList['basicInfo'].fieldList.forEach(item=>{
                    if(item.name=='姓名'){
                      item.value=res.data.user_name
                    }else if(item.name=='性别'){
                      if(item.value==''){
                        item.value=res.data.sex==0?'':res.data.sex
                      }                   
                    }else if(item.name=='年龄'){
                      item.value=res.data.age==0?'':res.data.age
                    }else if(item.name=='手机号'){
                      item.value=res.data.phone
                    }else if(item.name=='邮箱'){
                      item.value=res.data.email
                    }else if(item.name=='身份证'||item.name=='身份证号'){
                      item.value=res.data.identity
                    }
                  })
                })
              }
            })
            for (let key in data){
              if(key != 'otherInfo'){
                if(data[key]&&data[key].add){
                    content.state.addItem.push({type:data[key].tableName,list:[...JSON.parse(JSON.stringify(data[key].fieldLists[0]))]})
                }
              }else {
                data[key].forEach(e=>{
                  if(e.add){
                    content.state.addItem.push({type:e.tableName,list:[...JSON.parse(JSON.stringify(e.fieldLists[0]))]})
                  }
                })
              }
            }
          }
        })
      }) 
    },
    setmySignForm(content,params,){
      content.state.jobSelect=params.job
      content.state.mySignForm=params.data
      content.state.signForm={...JSON.parse(JSON.stringify(params.data))}
      console.log(content.state.signForm)
      content.state.signForm['basicInfo'].fieldList=content.state.signForm['basicInfo'].fieldList.filter((e)=>{
        if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
          if(e.value[0]){
            return true
          }
        }else if(e.type.name=='多选'){
          if(e.value.length>0){
            return true
          }
        }else {
          if(e.value!=""){
            
            return true
          }
        }
      })
      if(content.state.signForm['educationInfo']){
        content.state.signForm['educationInfo'].fieldLists=content.state.signForm['educationInfo'].fieldLists.map((option)=>{
          let arr = option.filter(e=>{
            if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
              if(e.value[0]){
                return true
              }
            }else if(e.type.name=='多选'){
              if(e.value.length>0){
                return true
              }
            }else {
              if(e.value&&e.value!=""){
                return true
              }
            }
          })
          console.log(arr)
          option = [...arr]              
          return option;
        })
        for(let i=0 ;i<content.state.signForm['educationInfo'].fieldLists.length;i++){
          if(content.state.signForm['educationInfo'].fieldLists[i].length==0){
            content.state.signForm['educationInfo'].fieldLists.splice(i,1)
            i=i-1
          }
        }
        console.log(content.state.signForm['educationInfo'].fieldLists)
        if(content.state.signForm['educationInfo'].fieldLists[0]){
          content.state.signForm['educationInfo']['showTitle'] = content.state.signForm['educationInfo'].fieldLists[0].find(item => !item.hide) ? true : false;
        }else {
          content.state.signForm['educationInfo']['showTitle'] = false
        }    
      }
      
      if(content.state.signForm['workInfo']){
        content.state.signForm['workInfo'].fieldLists=content.state.signForm['workInfo'].fieldLists.map((option)=>{
          let arr = option.filter(e=>{
            if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
              if(e.value[0]){
                return true
              }
            }else if(e.type.name=='多选'){
              if(e.value.length>0){
                return true
              }
            }else {
              if(e.value!=""){
                return true
              }
            }
          })            
          option = [...arr]
          return option;
        })
        for(let i=0 ;i<content.state.signForm['workInfo'].fieldLists.length;i++){
          if(content.state.signForm['workInfo'].fieldLists[i].length==0){
            content.state.signForm['workInfo'].fieldLists.splice(i,1)
            i=i-1
          }
        }
        if(content.state.signForm['workInfo'].fieldLists[0]){
          content.state.signForm['workInfo']['showTitle'] = content.state.signForm['workInfo'].fieldLists[0].find(item => !item.hide) ? true : false;
        }else {
          content.state.signForm['workInfo']['showTitle'] = false
        } 
      }
      
      if(content.state.signForm['otherInfo']){
        content.state.signForm['otherInfo'].map(item=>{
          if(item.add){
            item.fieldLists = item.fieldLists.map(option=>{
              let arr = option.filter(e=>{
                if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                  if(e.value[0]){
                    return true
                  }
                }else if(e.type.name=='多选'){
                  if(e.value.length>0){
                    return true
                  }
                }else {
                  if(e.value!=""){
                    return true
                  }
                }
              })            
              option = [...arr]
              return option;
            })
            for(let i=0 ;i<item.fieldLists.length;i++){
              if(item.fieldLists[i].length==0){
                item.fieldLists.splice(i,1)
                i=i-1
              }
            }
            if(item.fieldLists[0]){
              item.showTitle=item.fieldLists[0].find(item => !item.hide) ? true : false;
            }else {
              item['showTitle'] = false
            } 
            
          }else {
            item.fieldList=item.fieldList.filter((e)=>{
              if(e.type.name=='dateRangeYM'||e.type.name=='dateRangeYMD'){
                if(e.value[0]){
                  return true
                }
              }else if(e.type.name=='多选'){
                if(e.value.length>0){
                  return true
                }
              }else {
                if(e.value!=""){
                  return true
                }
              }
            })
            
            if(item.fieldList){
              item.showTitle=item.fieldList.find(item => !item.hide) ? true : false;
            }
            
          }
          return item;
        })
      }
      
    },
    setapplyEntryList(content,params){
      content.state.applyEntryList=params
    },
    handleOk(content,params){
      content.state.visible=false
    },
    handleCancel(content,params){
      content.state.visible=false
    },
    showSignModal(content,params){
      content.state.visible=true
    },
    detailOk(content,params){
      content.state.detailVisible=false
    },
    detailCancel(content,params){
      content.state.detailVisible=false
    },
    showDetailModal(content,params){
      content.state.detailVisible=true
    },
    init(content){
      content.state.applyEntryList={}
      content.state.mySignForm={}
    },
    applySave(content,params){
      return new Promise ((resolve,reject)=>{
        insertEntryApply(params).then(res=>{
          if(res.flag){
            content.state.userApplyId=res.data
          }
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    },
    SubmitOrder(content,params){
      return new Promise ((resolve,reject) =>{
        submitOrder(params).then (res =>{
          resolve(res)
        }).catch(error =>{
          reject(error)
        })
      })
    },
    paypost(content,params){
      return new Promise ((resolve,reject) =>{
        pay(params).then (res =>{
          resolve(res)
        }).catch(error =>{
          reject(error)
        })
      })
    },
    getOrderDetail(content,params){
      return new Promise ((resolve,reject) =>{
        orderDetail(params).then (res =>{
          resolve(res)
        }).catch(error =>{
          reject(error)
        })
      })
    },
    getHirePlanPay(content,params){
      return new Promise ((resolve,reject) =>{
        gethirePlanPay(params).then (res =>{
          if(res.flag){
            content.state.hirePlanpay=res.data.invoice
          }
          resolve(res)
        }).catch(error =>{
          reject(error)
        })
      })
    },
    entrancePhoto(content,params){
      return new Promise((resolve,reject)=>{
        entrancePhoto(params).then(res=>{
          resolve(res)
        }).catch(error=>{
          reject(error)
        })
      })
    }
  }
}
export default signUp