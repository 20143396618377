import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueRout from 'vue-router'
import 'ant-design-vue/dist/antd.css'
import './assets/font/iconfont.css'
import Antd from 'ant-design-vue'
import './views/plan/signUp/component/tips.less'
Vue.config.productionTip = false

//用axios直接使用this.$axios 就可以了
Vue.use(Antd)
Vue.use(VueRout)
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
